export default function sticky() {
  const element = document.querySelectorAll('[data-js-sticky]');
  if (element[0]) {
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
      element[0].classList.remove('js-sticky-fixed');
      element[0].classList.remove('js-sticky-absolute--bottom');
    };
    window.onload = function () {
      window.scrollTo(0, 0);
      element[0].classList.remove('js-sticky-fixed');
      element[0].classList.remove('js-sticky-absolute--bottom');
    };
    if (element.length > 0) {
      let position = element[0].dataset.jsSticky;
      let positionResponsive = element[0].dataset.jsStickyResponsive;
      let divOffset = offset(element[0].parentElement);
      window.onscroll = function () {
        toogleClass(element[0], divOffset.top, position, positionResponsive);
      };
    }
  }
}

function toogleClass(element, offsetTop, position, positionResponsive) {
  let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  const elementHeight = element.clientHeight;
  if (window.pageYOffset >= offsetTop) {
    if (position === 'left') {
      element.classList.add('js-sticky-fixed');

      if (element.parentElement.scrollHeight - scrollTop <= elementHeight) {
        element.classList.remove('js-sticky-fixed');
        element.classList.add('js-sticky-absolute--bottom');
      } else {
        element.classList.remove('js-sticky-absolute--bottom');
        element.classList.add('js-sticky-fixed');
      }
    } else {
      element.classList.add('js-sticky');
    }
  } else {
    element.classList.remove('js-sticky');
    element.classList.remove('js-sticky-fixed');
  }

  if (position === 'top') {
    element.classList.add('js-sticky--top');
  } else if (position === 'bottom') {
    element.classList.add('js-sticky--bottom');
  } else if (position === 'left') {
    let widthStyle = element.parentElement.clientWidth;
    element.classList.add('js-sticky--left');
    element.style.width = widthStyle - 25 + 'px';
  }

  if (positionResponsive) {
    if (window.pageYOffset >= offsetTop) {
      if (positionResponsive === 'top') {
        element.classList.add('js-sticky--top-responsive');
      } else if (positionResponsive === 'bottom') {
        element.classList.add('js-sticky--bottom-responsive');
      }
    } else {
      element.classList.remove('js-sticky--top-responsive');
      element.classList.remove('js-sticky--bottom-responsive');
    }
  }
}
function offset(el) {
  var rect = el.getBoundingClientRect(),
    scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
    scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
}
