import React from 'react';
import './advantagesCompare.scss';
import { AdvantagesCompareTable } from './advantagesCompareTable';
import { AdvantagesCompareColumns } from './advantagesCompareColumns';

const getTypeOfAdvantages = type => {
  const typeOfAdvantages = {
    'table': AdvantagesCompareTable,
    'columns': AdvantagesCompareColumns
  };

  return typeOfAdvantages[type] || null;
};

export const AdvantagesCompare = (props) => {
  const Advantages = getTypeOfAdvantages(props.type);

  return <Advantages props={props} />;

};
