import React, { useEffect } from 'react';
import Layout from '../../components/structures/Layout/layout.js';
import Dropdown from '../../components/modules/Dropdown/dropdown.js';
import contentFilter from '../../services/contentFilter/contentFilter.js';
import { Title } from '../../components/elements/Title/title.js';
import Section from '../../components/structures/Section/section';
import Wrapper from '../../components/structures/Wrapper/wrapper.js';
import { Button } from '../../components/elements/Button/button';
import RichText from '../../components/modules/RichText/richText';
import { Jumbotron } from '../../components/modules/Jumbotron/jumbotron';
import sticky from '../../services/sticky/sticky.js';
import { ReminderForm } from '../../components/modules/ReminderForm/reminderForm.js';
import getParameterUrl from '../../services/getParameterUrl/getParameterUrl';
import setCookie from '../../services/cookie/setCookie';
import { Steps } from '../../components/modules/Steps/steps.js';
import { AdvantagesCompare } from '../../components/modules/AdvantagesCompare/advantagesCompare';
import { useModal } from '../../hooks/useModal.js';
import { ModalWrapper } from '../../components/modules/Modal/ModalWrapper.js';
import { MostPopular } from '../../components/modules/MostPopular/mostPopular.js';
import replaceVariableSection from '../../services/replaceVariableSection/replaceVariableSection';

const Home = (props) => {
  const location = props.path;
  const variables = require('../../data/variables.json');
  const variablesForms = variables.forms;
  let sections = props.pageContext.sections;
  const dataSite = props.pageContext.dataSite;

  //replace variables Contntentful with API data on build
  for (const form in variablesForms) {
    if (typeof dataSite.data !== 'undefined') {
      sections = replaceVariableSection(variablesForms[form].price, dataSite.data.forms[form].price, sections);
    }
  }

  const sectionJumbotron = contentFilter(sections, 'jumbotron-home');
  const sectionDisclaimer = contentFilter(sections, 'disclaimer');
  const sectionInfobox = contentFilter(sections, 'infobox');
  const sectionMostPopular = contentFilter(sections, 'most-popular');
  const sectionSteps = contentFilter(sections, 'steps');
  const sectionAdvantages = contentFilter(sections, 'advantages');
  // const sectionApplications = contentFilter(sections, 'all-applications');
  const [isOpened, setIsOpened, modalLink, setModalLink] = useModal();


  const showModal = (link) => {
    setIsOpened(true);
    if (link) setModalLink(link);
  };

  useEffect(() => {
    sticky();
    let utm_source = getParameterUrl('utm_source');
    let utm_medium = getParameterUrl('utm_medium');
    let utm_campaign = getParameterUrl('utm_campaign');
    let cprice = getParameterUrl('cprice');
    let utm_content = getParameterUrl('utm_content');
    utm_source !== '' && setCookie('utm_source', utm_source, 30);
    utm_medium !== '' && setCookie('utm_medium', utm_medium, 30);
    utm_campaign !== '' && setCookie('utm_campaign', utm_campaign, 30);
    cprice !== '' && setCookie('cprice', cprice, 30);
    utm_content !== '' && setCookie('utm_content', utm_content, 30);
  }, []);

  const jumbotronBg = require(`../../images/${process.env.TEMPLATE}/style-scheme-${process.env.STYLE_SCHEME}/jumbo-m1-bg.png`).default;
  const jumbotronImg = require(`../../images/${process.env.TEMPLATE}/style-scheme-${process.env.STYLE_SCHEME}/jumbo-m1-img.png`).default;

  const templateStyle = props.pageContext.templateStyle;
  const jumbotronButton = templateStyle.home.jumbotronButton;

  return (
    <Layout
      pageContext={props.pageContext}
      location={location}
      nav
      typeHeader={templateStyle.header.type}
      footer={templateStyle.footer.type}
      prefooter={templateStyle.prefooter.type}
      dataSite={dataSite}
      cookie
    >
      {isOpened && (
        <ModalWrapper
          name="popup-legal"
          type="modal-confirmation"
          directions={false}
          cookie={false}
          cookieExpire={0}
          iconType="check"
          isOpened={isOpened}
          setIsOpened={setIsOpened}
          modalLink={modalLink}
          setModalLink={setModalLink}
        />
      )}
      <Section pad0 name={sectionInfobox.contentfulid}>
        <Dropdown
          alert
          closeBox
          trigger={sectionInfobox.content[0].title.title}
          contentBody={
            sectionInfobox.content[0].content && <RichText content={sectionInfobox.content[0].content} />
          }
        />
      </Section>
      <Section padSmall white jsSticky="top" jsStickyResponsive="bottom">
        <Wrapper narrow>
          <ReminderForm />
        </Wrapper>
      </Section>
      <Section pad0 name={sectionJumbotron.contentfulid}>
        <Jumbotron
          sc1
          topSpace
          bottomSpace
          limitWidthFirstCol
          hideSmSecond
          jumbotronBg={jumbotronBg}
          firstColumn={
            <div>
              {sectionJumbotron.content[0].firstColumn.map((item, i) => {
                return (
                  <React.Fragment key={i}>
                    {item.headText && (
                      <Title
                        tag="h1"
                        color="white"
                        size="regular"
                        content={item.headText}
                        white={jumbotronBg ? true : false}
                      />
                    )}
                    {item.bodyText && (
                      item.bodyText && <RichText content={item.bodyText} white={jumbotronBg ? true : false} />
                    )}
                    {item.btnText && (
                      <Button
                        type={jumbotronButton}
                        size="large"
                        width="width100"
                        iconArrow
                        dataqa={item.dataqa ? item.dataqa : false}
                        content={item.btnText}
                        onClickFunction={() => showModal(item.btnLink)}
                      />
                    )}
                  </React.Fragment>
                );
              })}
            </div>
          }
          secondColumn={
            <div>
              <img className="e-img__jumbotron" width={420} src={jumbotronImg} alt="" />
            </div>
          }
        />
      </Section>
      <Section sc1 name={sectionDisclaimer.contentfulid} small>
        <Wrapper>
          {sectionDisclaimer.content[0].textBody && <RichText
            grey
            small
            lineHeightReduced
            marg0
            content={sectionDisclaimer.content[0].textBody}
          />}
        </Wrapper>
      </Section>
      <Section sc1 name={sectionSteps.contentfulid}>
        <Wrapper middle>
          <Title tag="h2" fontStyle="centered" size="regular" content={sectionSteps.title} />
          <Steps
            cardsFlexDivs
            cardsIconWidth={80}
            cardsIconHeight={80}
            cardsIconCenter={true}
            sectionSteps={sectionSteps}
            iconStepType={'step'}
            numSeparate
          />
        </Wrapper>
      </Section>
      <Section sc1 padTop0 name={sectionMostPopular.contentfulid}>
        <Wrapper>
          <MostPopular
            btnIconArrow={true}
            sectionMostPopular={sectionMostPopular}
            templateStyle={templateStyle}
          />
        </Wrapper>
      </Section>
      <Section name={sectionAdvantages.contentfulid}>
        <Wrapper>
          <Title
            tag="h2"
            margin="margBot40"
            fontStyle="centered"
            size="regular"
            fontWeight="semiBold"
            content={sectionAdvantages.title}
          />
          <AdvantagesCompare
            showModal={showModal}
            sectionAdvantages={sectionAdvantages}
            type="table"
          />
        </Wrapper>
      </Section>
    </Layout>
  );
};

export default Home;
