import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './image.scss';

interface Props {
  className: string,
  src: string,
  alt: string,
  width: number,
  height: number,
  style: object,
  auto: boolean,
  widthSmFull: boolean
}

export const Image: React.FC<Props> = ({className, src, alt, width, height, style, auto, widthSmFull}) => {
  const classnames = classNames('m-img', {
    'm-img--auto': auto,
    'm-img--sm-full': widthSmFull,
  });

  return (
    <img
      className={className ? className + ` ` + classnames : classnames}
      src={src}
      alt={alt}
      width={width}
      height={height}
      style={style}
    />
  );
};

Image.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};