import React from 'react';
import { Title } from '../../elements/Title/title';
import { Card } from '../Card/card';
import { CardGroup } from '../CardGroup/cardGroup';
import RichText from '../RichText/richText';
import { Button } from '../../elements/Button/button';
import { Image } from '../../elements/Image/image';
import './advantagesCompare.scss';

export const AdvantagesCompareColumns = ({ props }) => {
  const { showModal } = props;
  return (
    <>
      <CardGroup className={`m-advantages-compare`}>
        {props.sectionAdvantages.content[0].cards.map((card, i) => {
          let iconAdvantage;
          iconAdvantage = require(`../../../images/${process.env.TEMPLATE}/style-scheme-${process.env.STYLE_SCHEME}/icon-advantages-${i + 1}.svg`).default;
          return (
            <Card
              borderRadius
              white
              shadow
              headPrimary
              fixHeight
              centered
              bgSc={i === 1}
              textWhite={i === 1}
              borderTop={i === 1}
              key={i}
              col={12}
              colLg={6}
              footerContent={
                card.btnText && (
                  <Button
                    type="brand"
                    centered
                    dataqa={card.dataqa ? card.dataqa : false}
                    content={card.btnText}
                    onClickFunction={() => showModal(card.btnLink)}
                  />
                )
              }
            >
              <Image src={iconAdvantage} width={`50`} />
              <Title size="small" fontWeight="semiBold" margin="margV20" content={card.name} />
              {card.bodyText && <RichText content={card.bodyText} />}
              {i === 1 && (
                <React.Fragment>
                  {props.sectionAdvantages.content[1].cards.map((cardCta, key) => {
                    return (
                      <React.Fragment key={key}>
                        <Button
                          type="brand"
                          width="width100"
                          centered
                          content={cardCta.btnText}
                          onClickFunction={() => showModal(cardCta.btnLink)}
                        />
                      </React.Fragment>
                    );
                  })}
                </React.Fragment>
              )}
            </Card>
          );
        })}
      </CardGroup>
    </>
  );
};
