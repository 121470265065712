import React, { useCallback } from 'react';
import { Image } from '../../elements/Image/image';
import { Text } from '../../elements/Text/text';
import { Title } from '../../elements/Title/title';
import './advantagesCompareTable.scss';

const getTitles = (cards) => cards.map((card) => card.name).reverse();

const getIcon = (numberOfIcon, advantageTextIndex) => {
  const iconAdvantage = require(`../../../images/${process.env.TEMPLATE}/style-scheme-${process.env.STYLE_SCHEME}/icon-advantages-${numberOfIcon}.png`).default;
  const iconSize = numberOfIcon === 2 ? '33' : '16';

  return (
    <div key={`advantagesIcon-${advantageTextIndex}-${numberOfIcon}`}>
      <Image src={iconAdvantage} width={iconSize} height={iconSize} />
    </div>
  );
};

const setTable = (cards) => {
  const rowsAdvantagesTable = setRows(cards);

  return <div className="m-advantages-compare-table">{rowsAdvantagesTable}</div>;
};

const setRows = (cards) => {
  const titles = getTitles(cards);
  const rowTitles = setTitles(titles);
  const rowsTextAndIcons = setRowsTextAndIcons(cards);

  return (
    <>
      {rowTitles}
      {rowsTextAndIcons}
    </>
  );
};

const setTitles = (titles) => (
  <div className="m-advantages-compare-row m-advantages-compare-row--titles">
    <div key={`title-0`}></div>
    {Object.entries(titles).map((title, indexTitle) => {
      return (
        <div key={`title-${indexTitle + 1}`}>
          <Title size="tiny" fontWeight="semiBold" content={title[1]} />
        </div>
      );
    })}
  </div>
);

const setRowsTextAndIcons = (cards) => {
  const arrayOfAdvantagesText = JSON.parse(cards[0].bodyText.raw).content;

  return arrayOfAdvantagesText?.map((advantageText, advantageTextIndex) => {
    return (
      <div
        key={`advantagesRow-${advantageTextIndex + 1}`}
        className="m-advantages-compare-row m-advantages-compare-row--text-icons"
      >
        <div>
          <Text size="small" margin="marginBot0" content={advantageText.content[0].value} />
        </div>
        {setIcons(cards, advantageTextIndex)}
      </div>
    );
  });
};

const setIcons = (cards, advantageTextIndex) => {
  const parsedIcons = [];
  for (let card in cards) {
    parsedIcons.push(getIcon(Number(card) + 1, advantageTextIndex));
  }
  return parsedIcons.reverse();
};

export const AdvantagesCompareTable = ({ props }) => {
  const cards = props.sectionAdvantages.content[0].cards;
  const advantagesTable = useCallback(setTable(cards));

  return <>{advantagesTable}</>;
};
